import React from "react";

import Image from "next/image";
import Link from "next/link";

import PageSeo from "@/shared/components/PageSeo";

function PageNotFound() {
    return (
        <div className="pageNotFoundWrapper">
            <PageSeo title="404 | The Gender Index" />
            <div className="pageNotFound">
                <div className="leftWrap">
                    <h1>404</h1>
                    <h3>Page not found</h3>
                    <p className="body-default-copy-large">
                        Lost in the cosmic web of time and space,
                        Our page vanished without a trace.
                        We&apos;re searching galaxies far and wide,
                        To bring it back with warp speed, side by side!
                    </p>
                    <Link href={"/"} className="action">
                        <button className='btn-green'>Go Home</button >
                    </Link>
                </div>
                <div className='rightWrap'>
                    <Image
                        src="/images/home.png"
                        alt="home image"
                        width={720}
                        height={500}
                    />
                </div>
            </div>
        </div>
    );
}

export default PageNotFound;
